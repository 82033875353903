import React from "react"
import { useState, useEffect } from 'react';

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import '@fortawesome/fontawesome-free/css/all.min.css';

const IndexPage = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const url = `https://api.annyiavilag.hu/news`;
            const data = await fetch(url).then(response => response.json());
            setNews(data);
        }
        fetchData();
    }, []);

    return (
        <Layout>
            <SEO title="Főoldal" />

            <h1>Hírek</h1>
            {news.length === 0 ? (
                <p>Hírek betöltése...</p>
            ) : (
                <div className="cardify-content-wrapper">
                    {news.map(newitem => (
                        <a
                            key={newitem.id}
                            className="cardify-content-card news-card"
                            href={newitem.url + '?reference=annyiavilag.hu'}
                            target="_blank"
                        >
                            <p>{newitem.title}</p>
                            {newitem.urlToImage !== 'null' &&
                                /(http(s?)):\/\//i.test(newitem.urlToImage) ? (
                                <img src={newitem.urlToImage} />
                            ) : (
                                <p />
                            )}
                        </a>
                    ))}
                </div>
            )}

            <hr />

            <h1>Hasznos szolgáltatások</h1>

            <div className="content-wrapper">
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <a href="https://www.munch.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Munch</h5>
                            <p className="cardify-content-alt">Ételmentő Kezdeményezés</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                        </a>

                        <a href="https://www.share-now.com/hu/hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">ShareNow</h5>
                            <p className="cardify-content-alt">CAR SHARING</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>

                        <a href="https://ferjkolcsonzes.hu/ferjkolcsonzes/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">FérjKölcsönzés</h5>
                            <p className="cardify-content-alt">Lakás körüli segítségnyújtás</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                        </a>

                        <a href="https://www.hubhub.com/hu/budapest-kiraly-26/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">HubDub Budapest</h5>
                            <p className="cardify-content-alt">Iroda/asztal bérlés</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>

                        <a href="https://kifli.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Kifli</h5>
                            <p className="cardify-content-alt">Ételszállítás</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>

                        <a href="https://greengo.com/hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">GreenGO</h5>
                            <p className="cardify-content-alt">E-CAR SHARING</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>
                    </div>
                </div>
            </div>

            <hr />

            <h1>Hasznos Térképek</h1>

            <div className="content-wrapper">
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <a href="https://www.kozkutak.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title"><i className="fas fa-hand-holding-water highlight-upcolor"></i> Közkutak</h5>
                            <p className="cardify-content-alt">Publikus ivókutak Magyaroroszágon</p>

                            <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                        </a>

                        <a href="https://www.geocaching.hu/geomap/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title"><i className="fas fa-gift highlight-upcolor"></i> Geocaching</h5>
                            <p className="cardify-content-alt">Geoládák Magyaroroszágon</p>

                            <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                        </a>

                        <a href="https://merretekerjek.hu/" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title"><i className="fas fa-bicycle highlight-upcolor"></i> Merretekerjek</h5>
                            <p className="cardify-content-alt">Városi-, túra- és hegyi útvonalak kerékpárosoknak</p>

                            <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                        </a>

                        <a href="https://turistautak.hu/maps/mapnik/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title"><i className="fas fa-mountain highlight-upcolor"></i> Turistautak</h5>
                            <p className="cardify-content-alt">Non-profit turistatérkép</p>

                            <p className="main-content-card-city"><i className="fas fa-tags highlight-upcolor"></i> Ingyenes</p>
                        </a>
                    </div>
                </div>
            </div>

            <hr />

            <h1>Kit támogassak? Kinek adományozzak?</h1>
            <p>Az általunk megadott listából ezt most könnyen eldöntheted.</p>

            <div className="content-wrapper">
                <h3 className="health-icon"><i className="fas fa-plus"></i> Egyesületek</h3>
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <a href="http://voroskereszt.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Magyar Vöröskereszt</h5>
                            <p className="cardify-content-alt">Nemzeti humanitárius társadalmi szervezet.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                        </a>
                        <a href="https://orokbefogadokegyovit.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Örökbe fogadok Egy Ovit</h5>
                            <p className="cardify-content-alt">Országos támogatási egyesület.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <h3 className="child-icon"><i className="fas fa-child"></i> Gyermekotthonok</h3>
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <a href="http://www.cseppgyermek.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Cseppkő Gyermekotthoni Központ</h5>
                            <p className="cardify-content-alt">Budapesti gyermekotthon.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>
                        <a href="http://www.hgyermekotthon.axelero.net/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Hűvösvölgyi Gyermekotthon</h5>
                            <p className="cardify-content-alt">Budapesti gyermekotthon.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>
                        <a href="http://waltnerkarolyotthon.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Dr. Waltner Károly Otthon</h5>
                            <p className="cardify-content-alt">Szegedi gyermekotthon.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Szeged</p>
                        </a>
                        <a href="http://www.remenysugarotthon.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Debreceni Reménysugár Gyermekotthon</h5>
                            <p className="cardify-content-alt">Debreceni gyermekotthon.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Debrecen</p>
                        </a>
                        <a href="http://www.bmgyk.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Baranya Megyei Gyermekvédelmi Központ</h5>
                            <p className="cardify-content-alt">Pécsi gyermekotthon.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Pécs</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <h3 className="animal-icon"><i className="fas fa-dog"></i> Menhelyek</h3>
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <a href="http://noeallatotthon.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Noé Állatotthon</h5>
                            <p className="cardify-content-alt">Budapesti állatmenhely.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Budapest</p>
                        </a>
                        <a href="https://www.tappancs.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Tappancs Tanya</h5>
                            <p className="cardify-content-alt">Szegedi állatmenhely.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Szeged</p>
                        </a>
                    </div>
                </div>
            </div>

            <div className="content-wrapper">
                <h3 className="other-icon"><i className="fas fa-question"></i> Egyéb</h3>
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper">
                        <a href="https://szurkolokazallatokert.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">Szurkolók Az Állatkínzás Ellen</h5>
                            <p className="cardify-content-alt">Állatok megmentése és a felelős állattartás megtanítása, betartatása, ellenőrzése.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                        </a>

                        <a href="https://tavoktatas2020.hu/?reference=annyiavilag.hu" className="cardify-content-card" target="_blank">
                            <h5 className="main-content-card-title">OKTONDI</h5>
                            <p className="cardify-content-alt">Online oktatási segédlet diákoknak.</p>

                            <p className="main-content-card-city"><i className="fas fa-city highlight-upcolor"></i> Országos</p>
                        </a>
                    </div>
                </div>
            </div>

            <hr />

            <h1>Online Közösségi Játékok</h1>

            <div className="content-wrapper">
                <h3 className="health-icon"><i className="fas fa-plus"></i> Közösségi</h3>
                <div className="header-wrapper">
                    <div className="cardify-content-wrapper support">
                        <a className="cardify-content-card" href="https://garticphone.com/?reference=annyiavilag.hu" target="_blank">
                            <h5 className="main-content-card-title">GarticPhone</h5>
                            <p className="cardify-content-alt">#fun #draw</p>
                        </a>
                        <a className="cardify-content-card" href="https://skribbl.io/?reference=annyiavilag.hu" target="_blank">
                            <h5 className="main-content-card-title">Skribbl.io</h5>
                            <p className="cardify-content-alt">#fun #draw</p>
                        </a>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default IndexPage
